<template>
  <v-dialog
    v-model="dialogAssuntos"
    @click:outside="$emit('update:dialogAssuntos', false)"
    @keydown.esc="$emit('update:dialogAssuntos', false)"
    max-width="700px"
    scrollable
  >
    <v-card>
      <v-card-title class="primary white--text pa-4">
        <v-icon dark left>mdi-subtitles-outline</v-icon>
        Assuntos
        <v-spacer></v-spacer>
        <v-icon dark @click="$emit('update:dialogAssuntos', false)"
          >mdi-close</v-icon
        >
      </v-card-title>
      <div class="pa-4 d-block d-sm-flex align-center justify-space-between">
        <v-text-field
          style="max-width: 200px;"
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
        >
        </v-text-field>
        <v-spacer class="d-none d-sm-flex"></v-spacer>

        <v-form
          @submit.prevent
          class="d-flex"
          ref="form"
          v-model="validForm"
          lazy-validation
        >
          <div class="d-flex mt-4 mt-sm-0">
            <v-text-field
              v-if="isAdding"
              v-model="assunto.descricao"
              label="Assunto"
              dense
              hide-details
              class="pr-4"
              ref="assuntos"
              prepend-icon="mdi-close"
              @click:prepend="clearAssunto"
            >
              <template v-slot:append>
                <v-menu offset-x :close-on-content-click="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon :color="`${assunto.cor ? assunto.cor : ''}`"
                        >mdi-palette
                      </v-icon>
                    </v-btn>
                  </template>
                  <v-color-picker
                    hide-canvas
                    show-swatches
                    hide-inputs
                    mode="hexa"
                    v-model="color"
                  ></v-color-picker>
                </v-menu>
              </template>
            </v-text-field>
            <v-btn
              v-if="!isAdding"
              color="secondary"
              class="white--text"
              @click="isAdding = !isAdding"
            >
              <v-icon left> mdi-plus</v-icon>
              Adicionar
            </v-btn>
            <v-btn
              v-if="isAdding"
              color="secondary"
              class="white--text"
              @click="createAssunto"
              :disabled="!validForm"
            >
              <v-icon left> mdi-check</v-icon>
              Salvar
            </v-btn>
          </div>
        </v-form>
      </div>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          :headers="headers"
          :search="search"
          :items="assuntos"
          :items-per-page="-1"
          hide-default-footer
          disable-sort
          height="400px"
          fixed-header
          class="data-tables"
        >
          <template v-slot:[`item.descricao`]="{ item, index }">
            <v-text-field
              v-if="editItem === index && isEditing"
              v-model="assunto_editar.descricao"
              dense
              hide-details
              :rules="formRules"
              required
              class="mb-2"
            ></v-text-field>
            <div v-else>{{ item.descricao }}</div>
          </template>

          <template v-slot:[`item.cor`]="{ item, index }">
            <v-menu
              v-if="editItem === index && isEditing"
              offset-x
              :close-on-content-click="true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon
                    :color="`${assunto_editar.cor ? assunto_editar.cor : ''}`"
                    >mdi-palette
                  </v-icon>
                </v-btn>
              </template>
              <v-color-picker
                hide-canvas
                show-swatches
                hide-inputs
                mode="hexa"
                v-model="colorEditar"
              ></v-color-picker>
            </v-menu>

            <v-icon v-else :color="`${item.cor ? item.cor : ''}`"
              >mdi-palette
            </v-icon>
          </template>

          <template v-slot:[`item.status`]="{ item, index }">
            <div class="d-flex align-center justify-center">
              <v-switch
                v-if="isEditing && index === editItem"
                class="ma-0 pa-0"
                hide-details
                :false-value="0"
                :true-value="1"
                dense
                v-model="assunto_editar.status"
              ></v-switch>
              <v-switch
                v-else
                class="ma-0 pa-0"
                hide-details
                :false-value="0"
                :true-value="1"
                disabled
                dense
                v-model="item.status"
              ></v-switch>
            </div>
          </template>

          <template v-slot:[`item.editar`]="{ item, index }">
            <!-- Editar -->
            <v-btn
              v-if="!isEditing || editItem != index"
              small
              icon
              class=""
              @click="editRow(item, index)"
            >
              <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>

            <div class="d-flex align-center justify-center">
              <!-- Salvar -->
              <v-btn
                v-if="isEditing && editItem === index"
                small
                icon
                class=""
                @click="updateAssunto(item)"
              >
                <v-icon>mdi-check</v-icon>
              </v-btn>

              <!-- Cancelar -->
              <v-btn
                v-if="isEditing && editItem === index"
                small
                icon
                class="ml-1"
                @click="closeEditing(item)"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  getAssuntos,
  postAssunto,
  putAssunto,
} from "@/api/staff/leeds_assuntos";
import { inputRequired } from "@/filters/index.js";
export default {
  name: "DialogAssuntos",

  props: {
    dialogAssuntos: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      assuntos: [],
      color: null,
      colorEditar: null,
      search: "",
      isAdding: false,
      isEditing: false,
      validForm: true,
      assunto_editar: {},
      formRules: [inputRequired],
      assunto: {},
      editItem: null,
      itemOriginal: null,
    };
  },

  watch: {
    color() {
      if (!this.color) return;
      this.assunto.cor = this.color.hex;
    },
    colorEditar() {
      if (!this.colorEditar) return;
      this.assunto_editar.cor = this.colorEditar.hex;
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "Assunto",
          value: "descricao",
        },
        {
          text: "Cor",
          value: "cor",
          sortable: false,
          width: "80px",
          align: "center",
        },
        {
          text: "Status",
          sortable: false,
          width: "80px",
          align: "center",
          value: "status",
        },
        {
          text: "Editar",
          sortable: false,
          width: "80px",
          align: "center",
          value: "editar",
        },
      ];
    },
  },

  methods: {
    editRow(item, index) {
      this.itemOriginal = { ...item };
      this.assunto_editar = { ...item };
      this.colorEditar = null;
      this.isEditing = true;
      this.isAdding = false;
      this.editItem = index;
    },
    fetchAssuntos() {
      this.loading = true;
      getAssuntos()
        .then((response) => {
          this.assuntos = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    createAssunto() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.assunto.status = 1;
        console.log(this.assunto);
        postAssunto(this.assunto)
          .then(() => {
            this.fetchAssuntos();
            this.isAdding = false;
            this.assunto.descricao = null;
          })
          .catch((error) => {
            console.log(error);
            if(error.status == 406) {
              this.$toast.error("Assunto já existente!");
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    updateAssunto() {
      if (
        JSON.stringify(this.itemOriginal) ===
        JSON.stringify(this.assunto_editar)
      ) {
        this.$toast.error("Por favor faça uma modificação antes de salvar");
        return;
      }
      if (
        this.assunto_editar.descricao === null ||
        this.assunto_editar.descricao === "" ||
        this.assunto_editar.descricao === undefined
      ) {
        this.$toast.error("Por favor preencha o campo com o descricao!");
        return;
      }
      if (this.$refs.form.validate()) {
        this.loading = true;
        putAssunto(this.assunto_editar.id, this.assunto_editar)
          .then(() => {
            this.loading = false;
            this.isEditing = false;
            this.editItem = null;
            this.fetchAssuntos();
          })
          .catch((error) => {
            if(error.status == 406) {
              this.$toast.error("Assunto já existente!");
            }
          });
      }
    },
    clearAssunto() {
      this.isAdding = false;
      this.assunto.descricao = null;
      this.color = null;
      this.assunto.cor = null;
    },

    closeEditing(item) {
      this.itemOriginal = { ...item };
      this.isEditing = false;
      this.editItem = null;
    },
  },

  async mounted() {
    await this.fetchAssuntos();
  },
};
</script>

<style></style>
