import api from "../axios_service.js";
export async function getAssuntos(filtros) {
  const response = await api.get(
    `/staff/anotacoes-assuntos${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export function postAssunto(assunto) {
  return api.post(`/staff/anotacoes-assuntos/add`, assunto);
}
export function putAssunto(id, assunto) {
  return api.put(`/staff/anotacoes-assuntos/${id}`, assunto);
}
